<template>
  <div class="viewContainer dashboard">
    <b-row>
      <b-col>
        <h1>{{ $t('dashboard.title')}}</h1>
      </b-col>
      <b-col v-if="isIntegrationServerOrLocalhost">
        <b-button variant="secondary" @click="exportAllData()" :disabled="exportDisabled" class="export">Exportieren</b-button> 
      </b-col>
      <!-- Hier muss noch Lokalisierung eingebaut werden -->
      <b-col>
        <b-dropdown 
          :text="renderPeriodText(selectedPeriod)"
          class="periodDropdown"
        >
          <b-dropdown-item v-for="option in periodOptions" :key="option" :value="option" @click="updatePeriod(option)">
            {{renderPeriodText(option)}}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <div class="widgetContainer">
      <b-row >
        <b-col class="pr-12">
          <div class="">
            <RevenueOverTimeWidget 
            v-if="revenueDetailDataLoaded"
            class="revenueOverTimeWidget widgetBox widgetBoxSmall"
            :from="periodValues[selectedPeriod].from"
            :to="periodValues[selectedPeriod].to"
            :previousFrom="periodValues[selectedPeriod].previousFrom"
            :revenueDetailData="revenueDetailData"
            />
          </div>
        </b-col>
        <b-col class="pl-12">
          <div class="widgetBox widgetBoxSmall" >
            <TopRevenueWidget 
              class="widgetPadding "
              :from="periodValues[selectedPeriod].from" 
              :to="periodValues[selectedPeriod].to"  
              :chartLimit="10" :clickAllowed="true" 
              @clicked="() => {showTopRevenueDetail=true}" 
              />
          </div>
       </b-col>
      </b-row>
      <b-row>
        <b-col class="pr-12">
          <div class="widgetBox">
            <InOutWidget
              class="widgetPadding"
              :from="periodValues[selectedPeriod].from"
              :to="periodValues[selectedPeriod].to"
            />
          </div>
        </b-col>
        <b-col class="pl-12">
          <div class="widgetBox">
            <RevenueDetailWidget
              v-if="revenueDetailDataLoaded"
              class="widgetPadding"
              :from="periodValues[selectedPeriod].from"
              :to="periodValues[selectedPeriod].to"
              :revenueDetailData="revenueDetailData"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal v-model="showTopRevenueDetail" size="lg" hide-footer >
      <div class="top100Modal">
        <TopRevenueWidget :from="periodValues[selectedPeriod].from" :to="periodValues[selectedPeriod].to" :chartLimit="100" :clickAllowed="false" />
      </div>
    </b-modal> 
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DateTimeService from '@/services/DateTimeService'
import RevenueDetailWidget from "@/components/RevenueDetailWidget.vue";
import TopRevenueWidget from "@/components/TopRevenueWidget.vue";
import RevenueOverTimeWidget from "@/components/RevenueOverTimeWidget.vue";
import InOutWidget from "@/components/InOutWidget.vue";
import DataService from '@/services/StrapiService'
import { createCSVArrayFromVenueWineResponse, writeArrayToCSVDownload } from '@/utils/exportArticles'

export default {
  name: "Home",
  components: {
    RevenueDetailWidget, TopRevenueWidget, RevenueOverTimeWidget, InOutWidget
  },
  created() {
    
    this.periodOptions = DateTimeService.getPeriodOptions(DataService.isIntegrationServerOrLocalhost())
    this.periodValues = DateTimeService.getPeriodValues()
    if( this.getPeriod !== '') {
      this.selectedPeriod = this.getPeriod
    }
    this.loadRevenueDetailData()
  },
  data() {
    return {
      periodOptions: [],
      selectedPeriod: "last7days",
      periodValues: {},
      showTopRevenueDetail: false,
      revenueDetailData: {},
      revenueDetailDataLoaded: false,
      exportDisabled: false
    };
  },
  computed: {
    isIntegrationServerOrLocalhost() {
      return DataService.isIntegrationServerOrLocalhost()
    },
    ...mapGetters(['getPeriod','getVenue'])
  },
  methods: {
    loadRevenueDetailData() {
      this.loading = true
      let selectedPeriod = this.selectedPeriod
      DataService.getRevenueDetailWidgetChart(this.periodValues[selectedPeriod].from, this.periodValues[selectedPeriod].to, ['change_amount','change_amount_glasses'], true).then((response) => {
        this.revenueDetailData = response['data'].data
        this.revenueDetailDataLoaded = true
        this.loading = false
      }).catch((err) => {
        // handle error
        this.loading = false
        console.log(err)
      })
    },
    renderPeriodText(option){
      if(option === 'today') {
        return this.$t('dateTimeServices.today')
      }
      if(option === 'last7days') {
        return this.$t('dateTimeServices.last7Days')
      }
      if(option === 'last14days') {
        return this.$t('dateTimeServices.last14Days')
      }
      if(option === 'thisMonth') {
        return this.$t('dateTimeServices.thisMonth')
      }
      if(option === 'lastMonth') {
        return this.$t('dateTimeServices.lastMonth')
      }
      if(option === 'thisQuarter') {
        return this.$t('dateTimeServices.thisQuarter')
      }
      if(option === 'lastQuarter') {
        return this.$t('dateTimeServices.lastQuarter')
      }
      if(option === 'thisYear') {
        return this.$t('dateTimeServices.thisYear')
      }
    },
    updatePeriod(selectedPeriod) {
      this.selectedPeriod = selectedPeriod
      this.$store.commit('updatePeriod', selectedPeriod)
      this.loadRevenueDetailData()
    },
    exportAllData() {
      this.exportDisabled = true
      DataService.getVenueWinesSorted('wine.winery.title', true).then((response) => { 
        var csvArray = createCSVArrayFromVenueWineResponse(response['data'])
        var fileName = this.getVenue.attributes.title + '_' + DateTimeService.dateToFileString(new Date())
        writeArrayToCSVDownload(csvArray, fileName, this.$papa )
        this.exportDisabled = false
      }).catch((err) => {
        // handle error
        console.log(err)
      })
    }
  }
};
</script>
<style scoped>
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
</style>