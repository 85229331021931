<template>
  <div>
  <b-row class="filterRow widgetPadding">
    <b-col>
      <h2>
       {{ $t('dashboard.revenueOverTime.title')}}
      </h2>
    </b-col>
  </b-row>
  <b-row class="g-0 mb-3">  
    <b-col class="generalRevenue">
      <b-col>
        <p>{{ $t('general.total')}}</p>
        <h2 v-if="responseChartData.totalRevenue">
          {{responseChartData.totalRevenue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}} {{getCurrency}}
        </h2>
        <h2 v-else>
          {{  $t('general.notDefined') }}
        </h2>
        <div :class="percentageState" class="percentage">
          <svg v-if="previousTimeframeData.totalRevenue > 0" class="" width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
            <path d="M.899 11 0 10.101l8.817-8.817H.727V0H11v10.272H9.716v-8.09z" fill="#0CBA4A" fill-rule="nonzero"/>
          </svg>
          <h2 v-if="previousTimeframeData.totalRevenue > 0" class="comparison">{{calculatePercentageIncrease(previousTimeframeData.totalRevenue, responseChartData.totalRevenue)}}<span v-if="previousTimeframeData.totalRevenue > 0"> %</span></h2>
          <h2 v-else>
          {{  $t('general.notDefined') }}
          </h2>
          <p v-if="previousTimeframeData.totalRevenue == 0" class="comparison">{{ $t('dashboard.revenueOverTime.noReferenceTimeframe')}}</p>

          <p v-if="previousTimeframeData.totalRevenue > 0" class="comparison">{{ $t('dashboard.revenueOverTime.turnOverComparision')}}</p>
        </div>
      </b-col>
    </b-col>
    <b-col class="turnOver">
      <p>{{ $t('dashboard.revenueOverTime.turnOverBottles')}}</p>
      <h2 v-if="responseChartData.totalBottleRevenue">
        {{responseChartData.totalBottleRevenue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}} {{getCurrency}}
      </h2>
      <h2 v-else>
        {{  $t('general.notDefined') }}
      </h2>
      <p>{{ $t('dashboard.revenueOverTime.turnOverGlasses')}}</p>
      <h2 v-if="responseChartData.totalGlassesRevenue">
        {{responseChartData.totalGlassesRevenue.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}} {{getCurrency}}
      </h2>
      <h2 v-else>
        {{  $t('general.notDefined') }}
      </h2>
    </b-col>
  </b-row>
  <b-row class="bottomRow widgetPadding">
    <b-row class="1stRow mb-4">
      <b-col>
        <p>{{ $t('dashboard.revenueOverTime.soldBottles')}}</p>
        <h2 v-if="responseChartData.bottlesFloat">
          {{getBottlesFloatSoldNumber}} {{ $t('general.bottles') }}
        </h2>
        <h2 v-else>
          {{  $t('general.notDefined') }}
        </h2>
      </b-col>
      <b-col>
        <p>{{ $t('dashboard.revenueOverTime.averageSalesPerBottle')}}</p>
        <h2 v-if="responseChartData.revenuePerBottleAvg">
          {{responseChartData.revenuePerBottleAvg.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}} {{getCurrency}}
        </h2> 
        <h2 v-else>
          {{  $t('general.notDefined') }}
        </h2>
      </b-col>
      <b-col>
        <p>{{ $t('dashboard.revenueOverTime.averageSalesPerGlass')}}</p>
        <h2 v-if="responseChartData.revenuePerGlassesAvg">
          {{responseChartData.revenuePerGlassesAvg.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}} {{getCurrency}}
        </h2>
        <h2 v-else>
          {{  $t('general.notDefined') }}
        </h2>
    </b-col>
    </b-row>
    <b-row class="2ndRow" >
      <b-col>
        <p>{{ $t('dashboard.revenueOverTime.topCountry')}}</p>
        <h2 v-if="revenueDetailData.landChartData.data.length > 0">
          {{ $t('countries.' + revenueDetailData.landChartData.data[0].localeKey)}} 
        </h2>
        <h2 v-else>
          {{  $t('general.notDefined') }}
        </h2>
      </b-col>
      <b-col>
        <p>{{ $t('dashboard.revenueOverTime.topRegion')}}</p>
        <h2 v-if="revenueDetailData.landChartData.data.length > 0">
          {{revenueDetailData.regionChartData.data[0].localeKey? $t('regions.' + revenueDetailData.regionChartData.data[0].localeKey) : revenueDetailData.regionChartData.data[0].title }}
        </h2>
        <h2 v-else>
          {{  $t('general.notDefined') }}
        </h2>
      </b-col>
      <b-col>
        <p>{{ $t('dashboard.revenueOverTime.topGrapeVariety')}}</p>
        <h2 v-if="revenueDetailData.landChartData.data.length > 0">
          {{ revenueDetailData.grapeVarietyChartData.data[0].title }}
        </h2>
        <h2 v-else>
          {{  $t('general.notDefined') }}
        </h2>
      </b-col>
    </b-row>
    <b-col>
     
    
    </b-col>

  </b-row>

  
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataService from '@/services/StrapiService'
import { getVenueCurrency, isFloat } from '@/utils/functions'

export default {
  name: "RevenueDetailWidget",
  components: {
  },
  data() {
    return {
      revenueChartFilter: 'revenue',
      responseChartData: {},
      previousTimeframeData: {},
      chartTypeData: {},
      loading: true,
      chartBackgrounds: [
        "#7edd37"
      ],
      revenuePercentage: Number,
    }
  },
  props: {
    from: String,
    to: String,
    previousFrom: String,
    revenueDetailData: Object
  },
  created () {
    this.loadWidgetData()
  },
  methods: {
    loadWidgetData() {
      this.loading = true
      this.loadPreviousTimeframeData(this.previousFrom, this.from)
      this.loadCurrentTimeFrameData(this.from, this.to)
      this.loading = false
    },
    loadCurrentTimeFrameData() {
      DataService.getRevenueOverTimeWidgetChart(this.from, this.to).then((response) => {
        this.responseChartData = response['data'].data
      }).catch((err) => {
            // handle error
          this.loading = false
          console.log(err)
      })
    },
    loadPreviousTimeframeData(previousFrom, from) {
      DataService.getRevenueOverTimeWidgetChart(previousFrom, from).then((response) => {
        this.previousTimeframeData = response['data'].data
      }).catch((err) => {
            // handle error
          this.loading = false
          console.log(err)
      })
    },
    transformDataToChart (dataObject) {
      var returnObject = dataObject

      returnObject.firstParam = dataObject.revenueAll
      returnObject.secondParam = dataObject.fullName
      return returnObject
    },
    transformOuterDataToChart (dataObject) {
      var returnObject = dataObject
      returnObject.data = dataObject.map(data => this.transformDataToChart(data))
      return returnObject
    },
    refreshChart (filter) {
      this.revenueChartFilter = filter
      switch (filter) {
        default:
          this.chartTypeData = this.transformOuterDataToChart(this.responseChartData.range)
      }
    },
    calculatePercentageIncrease(previousValue, currentValue) {
      const increase = currentValue - previousValue;
      if (previousValue === 0) {
        this.revenuePercentage = 0
        return 
      } 
      const percentageIncrease = (increase / previousValue) * 100;
      this.revenuePercentage = percentageIncrease.toFixed(0)
      return this.revenuePercentage
    }
  },
  computed: {
    getCurrency() {
      return getVenueCurrency(this.getVenue)
    },
    ...mapGetters(['getVenue']),
    percentageState() {
      if (this.revenuePercentage < 0) {
        
        if (this.revenuePercentage < -99) {
          return 'verynegative'
        }
        return 'negative'
      } 
      if (this.revenuePercentage > 0) {
        if (this.revenuePercentage > 99) {
          return 'verypositive'
        }
        return 'positive'
      } 
      return 'neutral'
    },
		getBottlesFloatSoldNumber() {
			var bottlesSold = this.responseChartData.bottlesFloat
			if (isFloat(bottlesSold)) {
				bottlesSold = bottlesSold.toFixed(2)
			}
			return bottlesSold
		}
  },
  watch: {
    from: function () { 
      
        this.loadWidgetData()
      
    },
    to: function () {}
  }
};
</script>
<style>


</style>